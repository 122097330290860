import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useHits} from 'react-instantsearch';
import Rating from './Rating';
import Pricing from '../../src/shared/pricing.js';
import PopularSwatches from './PopularSwatches';
import conciseSize from '../../utils/conciseSize';
import PriceDetailsDialog from '../../components/PriceDetailsDialog';
import PriceDetailsToolTip from '../../components/PriceDetailsToolTip';
import PriceDetailsContent from '../../components/PriceDetailsContent';
import QuoteTooltip from '../QuoteTooltip';
import {
  fireToolTipAnalytics,
  getProductDataUrl,
  getProductDataImageUrl,
  getEcomDataLayer
} from '../utils/data_utils';
import priceGridQuantities from '../../utils/priceGridQuantities';
import usePriceQuote from '../../hooks/queries/quotes';
import useScrollPosition from '../../hooks/useScrollPosition';
import {
  IMAGE_PIXEL_DENSITY_DPRS,
  ALGOLIA_PERMITTED_NAV_PARAMS
} from '../utils/algolia_constants';
import {
  mmsImageFingerprint,
  getColorImage,
  getIxParams,
  overrideUrlParamsForYDH
} from '../../utils/images';
import {newPricingDetails} from '../../utils/constants/signalmanConstants';
import {publishStyleAndColorIdMessage} from '../utils/keepShoppingMessages';
import {useMobileBreakpoint} from '@customink/pigment-react/lib/utils/useBreakpoint';
import ElevateSuperRush from '../ElevateSuperRush';
import {getMinimumDeliveryDays} from '../utils/delivery';
import rollbar from 'src/vendor/rollbar';

const ProductCard = (props) => {
  const {
    hit,
    currentFilters,
    categoryId,
    categoryName,
    defaultQuoteQty,
    customDesignPreview,
    setDefaultDesignPreview,
    designPreviewEnabledCategoryIds,
    quoteQuantityDirty,
    quantity,
    frontColor,
    backColor,
    siteWideSearch,
    upperProductCardBadge,
    lowerProductCardBadge,
    internalCatalog,
    useDynamicPricing,
    iframer,
    cid,
    compareHandler,
    compareStyles
  } = props;
  const {sendEvent} = useHits();
  const [activeColorId, setActiveColorId] = useState(0);
  const [initalColorRefinementRender, setInitialColorRefinementRender] =
    useState(true);
  const [noMoreLaydowns, setNoMoreLaydowns] = useState(false);
  const [quote, setQuote] = useState('');
  const [quoteQty, setQuoteQty] = useState(quantity);
  const [saveScrollPosition, restoreScrollPosition] = useScrollPosition();
  const [colorImageUrls, setColorImageUrls] = useState(() =>
    colorImageUrlsHash()
  );
  const [imageSrc, setImageSrc] = useState(() => getProductCardImageUrl());
  const [imageSrcSet, setImageSrcSet] = useState(() => imgSrcsetAttribute());

  const comparisonLimit = 4;
  const priceRef = useRef(null);
  const isNewPricingDetailsTest = newPricingDetails === 'test';
  const isMobile = useMobileBreakpoint();
  const minDeliveryDays = getMinimumDeliveryDays(hit?.rush_delivery_turntimes);

  let pricing = new Pricing();
  const pricingAlgorithm = pricing.defaultAlgorithm;
  let pricingData = hit?.default_unit_prices?.find((dup) => {
    return (
      dup?.algorithm === pricingAlgorithm &&
      dup?.quantity === defaultQuoteQty &&
      dup?.price
    );
  });

  function colorImageUrlsHash() {
    return hit.colors?.reduce((acc, color) => {
      acc[color.id] = colorImgUrl(color);
      return acc;
    }, {});
  }

  const colorRefinements = () => {
    return (
      currentFilters.find(
        (refinementItem) => refinementItem.attribute === 'colors.simple_names'
      ) ||
      currentFilters.find(
        (refinementItem) =>
          refinementItem.attribute === 'min_qty_colors.simple_names'
      )
    );
  };

  const currentColors = () => {
    const colorFilters = colorRefinements();
    if (colorFilters) {
      let colors = colorFilters.refinements?.map(
        (colorFilter) => colorFilter.value
      );
      return colors || [];
    } else {
      return [];
    }
  };

  const sizingSummary = () => {
    const MASKS_SIZE_OVERRIDE_STYLES = [
      862700, 895900, 855000, 885900, 839400, 986900, 987000
    ];
    if (MASKS_SIZE_OVERRIDE_STYLES.includes(parseInt(hit.product_id))) {
      return 'Youth & Adult';
    } else if (hit.sizes?.length > 1) {
      const {0: first, length, [length - 1]: last} = hit.sizes;
      const sizeRange = {first, last};
      return `${conciseSize(first)}-${conciseSize(last)}`;
    } else {
      return hit.sizes && hit.sizes[0];
    }
  };

  const styleMinQty = () => {
    if (hit.no_minimum === 1 || minQtyToggleSet()) {
      return 'No Minimum';
    } else {
      let minQtyString = `Minimum ${hit.minimum_quantity}`;
      return minQtyString;
    }
  };

  const noMinQtyClass = () => {
    return hit.minimum_quantity === 1 ? ' pc-ProductCard-detailQty--noMin' : '';
  };

  const findFirstActiveColorSwatch = () => {
    let colors = hit?.colors;
    let colorFilters = currentColors();
    const color = colors?.find((color) =>
      color.simple_names.some((csn) => colorFilters.includes(csn))
    );
    return color?.id;
  };

  const isSelectedColor = (color) => {
    return activeColorId === color.id;
  };

  const clickColorSwatch = (event, color) => {
    event.preventDefault();
    if (!noMoreLaydowns) setNoMoreLaydowns(true);
    setActiveColorId(color.id);
    setInitialColorRefinementRender(false);
    ProductCatalog.PopularColors.fireInteraction();
  };

  useEffect(() => {
    // observe MUI setting overflow to hidden and override
    const body = document.body;
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'style'
        ) {
          if (body.style.overflow === 'hidden') {
            body.style.removeProperty('overflow');
          }
        }
      });
    });

    observer.observe(body, {
      attributes: true,
      attributeFilter: ['style']
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (initalColorRefinementRender) {
      setActiveColorId(findFirstActiveColorSwatch());
    }
  });

  useEffect(() => {
    if (colorRefinements()) {
      setActiveColorId(findFirstActiveColorSwatch());
    }
  }, [currentFilters]);

  const preferredColorId = hit?.category_images?.find(
    (cat) => cat.id == categoryId
  )?.preferred_color_id;
  const quotableProductId =
    activeColorId ||
    preferredColorId ||
    hit?.colors?.[0]?.id ||
    hit?.product_id;
  const quotableMinQty =
    hit?.colors?.find((col) => quotableProductId === col.id)?.min_qty ||
    hit.minimum_quantity;
  const quotableColor = {
    minQty: quotableMinQty || hit.minimum_quantity,
    singlesEnabled: hit.has_singles_enabled_colors && quotableMinQty === 1
  };
  const quotableQuantities = priceGridQuantities(
    quotableColor.minQty,
    quotableColor.singlesEnabled,
    hit.specialty
  );
  const quotesQuery = usePriceQuote(
    quotableProductId,
    [...new Set([quantity, ...quotableQuantities])],
    frontColor,
    backColor
  );
  const priceDetailsData =
    quotesQuery?.data &&
    quotableQuantities.reduce((acc, key) => {
      if (quotesQuery.data.hasOwnProperty(key)) {
        acc[key] = quotesQuery.data[key];
      }
      return acc;
    }, {});
  const pricePlaceholders = quotableQuantities.reduce((acc, quantity) => {
    acc[quantity] = '---';
    return acc;
  }, {});

  useEffect(() => {
    if (useDynamicPricing && !hit.metadata?.STYLES_TARGET_CATEGORY_PROMOTION) {
      if (
        quantity === defaultQuoteQty &&
        !quoteQuantityDirty &&
        activeColorId === 0 &&
        pricingData != null
      ) {
        setQuote(pricingData?.price);
        setQuoteQty(quantity);
        priceRef.current.style.display = 'block';
      } else if (
        quantity < hit.minimum_quantity &&
        !hit.has_singles_enabled_colors
      ) {
        setQuote('minimum quantity not met');
        setQuoteQty('');
      } else if (
        hit.color_limit > 0 &&
        Math.max(frontColor, backColor) > hit.color_limit
      ) {
        setQuote(`product max of ${hit.color_limit} ink colors`);
        setQuoteQty('');
      } else {
        quotesQuery.refetch();
      }
    }
  }, [
    hit,
    quantity,
    frontColor,
    backColor,
    activeColorId,
    useDynamicPricing,
    quotesQuery.refetch
  ]);

  useEffect(() => {
    if (quotesQuery.data) {
      setQuote(quotesQuery.data[quantity]);
      setQuoteQty(quantity);
      priceRef.current.style.display = 'block';
    }
  }, [quotesQuery.data]);

  useEffect(() => {
    if (quotesQuery.error) {
      setQuote('error calculating price');
      setQuoteQty('');
    }
  }, [quotesQuery.error]);

  useEffect(() => {
    setColorImageUrls(colorImageUrlsHash());
    setImageSrc(getProductCardImageUrl());
    setImageSrcSet(imgSrcsetAttribute());
  }, [customDesignPreview, noMoreLaydowns]);

  const priceString = (segment) => {
    let showQuote;
    let showQty;
    if (useDynamicPricing) {
      if (
        (quantity === defaultQuoteQty || (siteWideSearch && defaultQuoteQty)) &&
        !quoteQuantityDirty &&
        pricingData?.price
      ) {
        showQuote = pricingData.price;
        showQty = defaultQuoteQty;
      } else if (isNaN(parseFloat(quote))) {
        if (
          frontColor > 4 ||
          backColor > 4 ||
          quote?.startsWith('product max')
        ) {
          if (segment === 'price') return '';
          if (
            quote?.startsWith('product max') ||
            !hit.has_singles_enabled_colors
          ) {
            return 'reduce ink count to get pricing for this product';
          } else {
            return 'reduce ink count or select another color';
          }
        }
        if (priceRef.current) {
          priceRef.current.style.display = 'none';
        }
        return segment === 'price' ? '' : 'error calculating price';
      } else {
        showQuote = quote;
        showQty = quoteQty;
      }
    } else {
      if (pricingData == null) {
        return '';
      }
      showQuote = pricingData.price;
      showQty = defaultQuoteQty;
    }
    let priceNum = parseFloat(showQuote);
    let priceUsd = priceNum.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    let priceTxt = `each for ${parseInt(showQty)} items`;
    if (segment === 'price') {
      return priceUsd;
    } else if (segment === 'without-price') {
      return priceTxt;
    } else {
      return `${priceUsd} ${priceTxt}`;
    }
  };

  const displayPrice = () => {
    return (
      ((categoryId || siteWideSearch) && pricingData != null) ||
      quoteQuantityDirty
    );
  };

  function catalogImgUrl(categoryImage) {
    if (categoryImage?.lab_image_eligible) {
      let color = hit?.colors?.find(
        (col) => categoryImage.preferred_color_id === col.id
      );
      if (color && color.image_url) {
        let ixParams = ixParamsForCard(color.image_url);
        return getColorImage(
          color.id,
          hit,
          mmsImageFingerprint(hit),
          ixParams,
          noMoreLaydowns,
          iframer,
          true,
          false,
          customDesignPreview,
          setDefaultDesignPreview,
          ProductCatalog.LocalStorage.read('default_design_preview'),
          designPreviewEnabledCategoryIds
        );
      }
    }
  }

  function ixParamsForCard(fallback, catId = categoryId) {
    let ixParams = getIxParams(hit, catId, fallback);
    ixParams.extended = true;
    ixParams.ixw = 270;
    return ixParams;
  }

  function categoryProductCardImage() {
    let categoryImage = hit?.category_images?.find(
      (cat) => cat.id == categoryId
    );
    let colorId = hit.product_id;
    if (categoryImage) {
      const preferredColor = hit?.colors?.find(
        (col) => categoryImage.preferred_color_id === col.id
      );
      if (preferredColor) colorId = preferredColor.id;
      if (!categoryImage.lab_image_eligible) return hit.style_card_image_url;
    }
    if (categoryId && !siteWideSearch) {
      const catImgUrl = catalogImgUrl(categoryImage);
      const imgurl =
        catImgUrl ||
        (categoryImage && categoryImage.image_url) ||
        hit.style_card_image_url;

      const ixParams = ixParamsForCard(imgurl);
      return getColorImage(
        colorId,
        hit,
        mmsImageFingerprint(hit),
        ixParams,
        noMoreLaydowns,
        iframer,
        categoryImage?.lab_image_eligible,
        false,
        customDesignPreview,
        setDefaultDesignPreview,
        ProductCatalog.LocalStorage.read('default_design_preview'),
        designPreviewEnabledCategoryIds
      );
    } else if (
      siteWideSearch &&
      !props.ydhSettings.overrides[parseInt(hit.product_id)]
    ) {
      categoryImage = hit?.category_images?.find(
        (cat) => cat.id === hit.primary_category_id
      );
      const catImgUrl = catalogImgUrl(categoryImage);
      const imgurl =
        catImgUrl ||
        (categoryImage && categoryImage.image_url) ||
        hit.style_card_image_url;

      const preferredColor = hit?.colors?.find(
        (col) => categoryImage?.preferred_color_id === col.id
      );
      if (preferredColor) colorId = preferredColor?.id;
      const ixParams = ixParamsForCard(imgurl, hit?.primary_category_id);
      return getColorImage(
        colorId,
        hit,
        mmsImageFingerprint(hit),
        ixParams,
        noMoreLaydowns,
        iframer,
        categoryImage?.lab_image_eligible,
        false,
        customDesignPreview,
        setDefaultDesignPreview,
        ProductCatalog.LocalStorage.read('default_design_preview'),
        designPreviewEnabledCategoryIds
      );
    } else {
      let ixParams = ixParamsForCard(hit.style_card_image_url);
      let labImageEligible = false;
      if (
        siteWideSearch &&
        !props.ydhSettings.overrides[parseInt(hit.product_id)]
      ) {
        const primaryCategoryId = hit.primary_category_id;
        if (primaryCategoryId) {
          const primaryCategoryImage = hit?.category_images?.find(
            (cat) => cat.id == primaryCategoryId
          );
          const preferredColor = hit?.colors?.find(
            (col) => primaryCategoryImage?.preferred_color_id === col.id
          );
          if (preferredColor) colorId = preferredColor.id;
          if (primaryCategoryImage)
            labImageEligible = primaryCategoryImage.lab_image_eligible;
          ixParams = ixParamsForCard(primaryCategoryImage?.image_url);
        }
      }
      return getColorImage(
        colorId,
        hit,
        mmsImageFingerprint(hit),
        ixParams,
        noMoreLaydowns,
        iframer,
        labImageEligible,
        siteWideSearch,
        customDesignPreview,
        setDefaultDesignPreview,
        ProductCatalog.LocalStorage.read('default_design_preview'),
        designPreviewEnabledCategoryIds
      );
    }
  }

  function getProductCardImageUrl() {
    const algoliaImageUrl = categoryProductCardImage();
    if (props.isFirstHit && props.firstImage) {
      props.firstImage !== algoliaImageUrl &&
        rollbar.error('mismatched image for style', hit?.product_id);
      return props.firstImage;
    } else {
      return algoliaImageUrl;
    }
  }

  const productLink = () => {
    if (hit.target_url) {
      return hit.target_url;
    } else {
      return (
        hit.breadcrumbs && hit?.breadcrumbs?.[hit?.breadcrumbs?.length - 1].path
      );
    }
  };

  const capitalize = (str) => {
    if (str) {
      return str[0].toUpperCase() + str.slice(1).toLowerCase();
    } else {
      return '';
    }
  };

  const minQtyToggleSet = () => {
    return currentFilters.some(
      (currentFilter) =>
        currentFilter.attribute === 'has_singles_enabled_colors'
    );
  };

  const renderProductDetails = () => {
    const tooltipQuantity =
      quoteQty || (quoteQuantityDirty && quantity) || defaultQuoteQty;
    const onTooltipOpen = () => {
      if (isNewPricingDetailsTest && !quotesQuery?.data) {
        quotesQuery.refetch();
      }
      fireToolTipAnalytics(categoryId, categoryName, hit.name);
      window.dataLayer.push({
        event: 'interaction',
        interaction_action: 'pricing details',
        interaction_category: 'product listings page',
        interaction_label: hit.name
      });
    };
    const body = document.body;
    return (
      <>
        {currentColors().length === 0 && (
          <PopularSwatches
            colorsIn={hit.colors}
            styleId={hit.product_id}
            onClickEvt={clickColorSwatch}
            onMouseEnterEvt={clickColorSwatch}
            useMinQty={minQtyToggleSet()}
          />
        )}
        {hit.rating_count > 0 && (
          <Rating
            ratingScore={hit.rating_score}
            ratingCount={hit.rating_count}
            name={hit.name}
            generalComments={hit.general_comments}
          />
        )}
        <div className="pc-ProductCard-detailExtra">
          <div className="pc-ProductCard-detailSizeSummary">
            {sizingSummary()}
          </div>
          <div className={'pc-ProductCard-detailQty' + noMinQtyClass()}>
            {styleMinQty()}
          </div>
        </div>
        <div className="pc-ProductCard-detailCompBadge">
          {hit.complements_badge}
        </div>
        {/* TODO: Use data from Algolia once implemented */}
        {Boolean(minDeliveryDays) && (
          <ElevateSuperRush deliveryRushDays={minDeliveryDays} />
        )}
        <div
          className="pc-ProductCard-detailPrice"
          style={{visibility: displayPrice ? 'visible' : 'hidden'}}
          ref={priceRef}>
          {displayPrice && priceString('price').startsWith('$') && (
            <>
              <div>
                <span className="pc-ProductCard-detailPriceAmount">{priceString('price')}</span>
                <span>{' ' + priceString('without-price')}</span>
              </div>
              <div style={{display: 'flex'}}>
                {(isNewPricingDetailsTest &&
                  (isMobile ? (
                    <PriceDetailsDialog
                      title={hit.name}
                      onExitedHandler={() => {
                        body.style.removeProperty('position');
                        body.style.removeProperty('top');
                        body.removeAttribute('style');
                        restoreScrollPosition();
                      }}
                      onOpenHandler={() => {
                        const scrollPosition = saveScrollPosition();
                        body.style.position = 'fixed';
                        body.style.top = `-${scrollPosition}px`;
                        onTooltipOpen();
                      }}
                      priceDetails={
                        <PriceDetailsContent
                          backColor={backColor}
                          frontColor={frontColor}
                          priceDetailQuoteData={
                            priceDetailsData || pricePlaceholders
                          }
                        />
                      }
                    />
                  ) : (
                    <PriceDetailsToolTip
                      onOpenHandler={() => {
                        onTooltipOpen();
                      }}
                      priceDetails={
                        <PriceDetailsContent
                          backColor={backColor}
                          frontColor={frontColor}
                          priceDetailQuoteData={
                            priceDetailsData || pricePlaceholders
                          }
                        />
                      }
                    />
                  ))) || (
                  <QuoteTooltip
                    backColor={backColor}
                    frontColor={frontColor}
                    onTooltipOpen={onTooltipOpen}
                    quantity={tooltipQuantity}>
                    <span className="pc-ProductCard-detailPriceCta">
                      Pricing Details
                    </span>
                  </QuoteTooltip>
                )}
              </div>
            </>
          )}
          {displayPrice &&
            !priceString('price').startsWith('$') &&
            priceString()}
        </div>
        <div className="pc-ProductCard-detailSwatches">
          {hit.colors?.map((color) => {
            let colorSimpleNames = color.simple_names;
            let colorFilters = currentColors();
            let validColorSwatch = colorSimpleNames.some((csn) =>
              colorFilters.includes(csn)
            );
            if (validColorSwatch) {
              let dataAttribute = {};
              dataAttribute['data-id'] = color.id;
              dataAttribute['data-min-qty'] = color.min_qty;
              color.simple_names?.map((simple_name) => {
                dataAttribute[`data-name-${simple_name}`] = '1';
              });
              return (
                <div className="pc-ProductCard-swatch" key={color.name}>
                  <div
                    className={
                      isSelectedColor(color)
                        ? 'pc-Swatch is-selected'
                        : 'pc-Swatch'
                    }
                    onClick={(event) => clickColorSwatch(event, color)}
                    onMouseEnter={(event) => clickColorSwatch(event, color)}
                    style={{backgroundColor: color?.rbgs[0]}}
                    title={color.name}
                    {...dataAttribute}>
                    {color?.trim_hex && (
                      <div
                        className="pc-Swatch-trim"
                        style={{backgroundColor: color?.trim_hex}}></div>
                    )}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </>
    );
  };

  function colorImgUrl(color) {
    const ydhCustomSettings =
      props.ydhSettings.overrides[parseInt(hit.product_id)];
    const ydhGeneralSettings = {
      defaultSettings: props.ydhSettings.default,
      nonUrlQueryParams: props.ydhSettings.nonUrlQueryParams
    };
    if (activeColorId && ydhCustomSettings) {
      const design =
        customDesignPreview?.url ||
        (hit.colors &&
        hit.colors.map((c) => c.id)?.includes(color.id) &&
        ydhCustomSettings.colors &&
        ydhCustomSettings.colors[color.id]
          ? ydhCustomSettings.colors[color.id]?.design
          : ydhCustomSettings?.design);
      return overrideUrlParamsForYDH(
        color.image_url,
        hit,
        design,
        ydhCustomSettings,
        ydhGeneralSettings
      );
    }
    if (categoryId || siteWideSearch) {
      if (ydhCustomSettings) {
        const design = customDesignPreview?.url || ydhCustomSettings?.design;
        return overrideUrlParamsForYDH(
          color.image_url,
          hit,
          design,
          ydhCustomSettings,
          ydhGeneralSettings
        );
      }
      let catId = categoryId || hit.primary_category_id;
      const ixParams = ixParamsForCard(
        color.image_url,
        hit.primary_category_id
      );
      if (iframer) {
        if (cid) {
          ixParams.design = cid;
          ixParams.autoNegate = 0;
        } else {
          delete ixParams.design;
        }
      }
      const categoryImage = hit?.category_images?.find(
        (cat) => cat.id == catId
      );
      return getColorImage(
        color.id,
        hit,
        mmsImageFingerprint(hit),
        ixParams,
        noMoreLaydowns,
        iframer,
        categoryImage?.lab_image_eligible,
        false,
        customDesignPreview,
        setDefaultDesignPreview,
        ProductCatalog.LocalStorage.read('default_design_preview'),
        designPreviewEnabledCategoryIds
      );
    }
    return color.image_url;
  }

  function imgSrcsetAttribute() {
    let srcsetArray = [];
    let imgSrcUrl =
      props.isFirstHit && props.firstImage
        ? props.firstImage
        : categoryProductCardImage();
    for (const key in IMAGE_PIXEL_DENSITY_DPRS) {
      if (Object.hasOwnProperty.call(IMAGE_PIXEL_DENSITY_DPRS, key)) {
        let srcsetUrl = `${imgSrcUrl} ${key}`;
        srcsetArray.push(srcsetUrl);
      }
    }
    return srcsetArray.join(', ');
  }

  const productLinkParams = () => {
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.forEach((_value, key) => {
      if (
        !ALGOLIA_PERMITTED_NAV_PARAMS.some((param) => key.startsWith(param))
      ) {
        searchParams.delete(key);
      }
    });

    if (activeColorId) {
      searchParams.append('PK', activeColorId);
    }

    if (searchParams.get('min_qty[]')) {
      searchParams.delete('min_qty[]');
      searchParams.append('quote_qty', 'min');
    }
    if (!searchParams.get('PK') && activeColorId && colorRefinements()) {
      searchParams.append('PK', activeColorId);
    }
    let paramString = searchParams.toString();
    if (paramString) {
      paramString = `?${paramString}`;
    }
    return paramString;
  };

  const applyToComparisonForm = (hit) => {
    let activeCompareImageUrl = document
      .querySelector(`[data-style-id="${hit.product_id}"]`)
      .querySelector('img.pc-ProductCard-imageColor.is-active')?.src;
    if (!activeCompareImageUrl) {
      activeCompareImageUrl = getProductCardImageUrl();
    }
    const comparedHit = {
      compareImageUrl: activeCompareImageUrl,
      productLink: productLink(),
      ...hit
    };
    if (
      !compareStyles.some((style) => style.product_id === hit.product_id) &&
      compareStyles.length < comparisonLimit
    ) {
      compareHandler(
        [...compareStyles, comparedHit],
        CustomInk.Metrics.fireEvent(
          'compare products',
          'select',
          `for select ${compareStyles.length + 1}`
        )
      );
    } else {
      compareHandler(
        compareStyles.filter((style) => style.product_id !== hit.product_id),
        CustomInk.Metrics.fireEvent('compare products', 'deselect', 'card')
      );
    }
  };

  const comparedStyle = () => {
    return compareStyles.some((style) => style.product_id === hit.product_id);
  };

  const keepShoppingRouting = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('keep_shopping') === 'true';
  };

  const linkProps = (currentHit) => {
    const urlColorId =
      new URLSearchParams(productLinkParams()).get('PK') || null;
    let colorId = !Number(urlColorId)
      ? hit.colors?.[0]?.id || null
      : urlColorId;
    if (keepShoppingRouting()) {
      return {
        onClick: () =>
          publishStyleAndColorIdMessage(currentHit.product_id, colorId)
      };
    } else {
      return {
        href: `${productLink()}${productLinkParams()}`,
        onClick: () => {
          sendEvent('click', hit, 'ProductClicked');
        }
      };
    }
  };

  return (
    <div
      className="pc-ProductCard"
      style={{display: hit.colors ? '' : 'none'}}
      data-insights-object-id={`${hit.product_id}-none`}
      data-insights-position={hit.__position}
      data-insights-query-id={hit.__queryID}
      data-style-id={parseInt(hit.product_id)}
      data-style-name={hit.name}
      data-brand-name={hit.manufacturer}
      data-item-category={hit.breadcrumbs?.[0]?.name
        ?.toLowerCase()
        ?.replace(/\s/g, '_')}
      data-item-category2={hit.breadcrumbs?.[1]?.name
        ?.toLowerCase()
        ?.replace(/\s/g, '_')}
      data-item-category3={hit.breadcrumbs?.[2]?.name
        ?.toLowerCase()
        ?.replace(/\s/g, '_')}
      data-testid="productCard">
      <div className="pc-ProductCard-content">
        <a className="pc-ProductCard-link" {...linkProps(hit)}>
          {' '}
          <div className="pc-ProductCard-image">
            <div className="pc-ProductCard-imageWrapper">
              <img
                className={
                  activeColorId
                    ? 'pc-ProductCard-imageColor is-active'
                    : 'pc-ProductCard-imagePrimary'
                }
                data-testid="imagePrimary"
                src={activeColorId ? colorImageUrls[activeColorId] : imageSrc}
                srcSet={
                  activeColorId ? colorImageUrls[activeColorId] : imageSrcSet
                }
                alt={hit.name}
              />
              <div className="pc-ProductCard-badgeHolder">
                {upperProductCardBadge && upperProductCardBadge}
                <div className="product-badge product-badge--favorite"></div>
                {lowerProductCardBadge && lowerProductCardBadge}
              </div>
            </div>
          </div>
          <div className="pc-ProductCard-details">
            <div className="pc-ProductCard-detailTitle">{hit.name}</div>
            {!hit.metadata?.STYLES_TARGET_CATEGORY_PROMOTION &&
              renderProductDetails()}
          </div>
          {hit.status === 'inactive' && (
            <div className="pc-ProductCard-oos">
              <span>Out of Stock</span>
            </div>
          )}{' '}
        </a>
        <div
          className="pc-ProductCard-dataLayer pc-ProductCard-schemaInfo"
          data-id={hit.product_id}
          data-name={hit.name}
          data-brand={hit.manufacturer}
          data-url={getProductDataUrl(productLink())}
          data-image={getProductDataImageUrl(hit.product_id)}
          data-category={`[${hit?.breadcrumbs?.map((cat) => `"${cat.name}"`).splice(0, hit?.breadcrumbs?.length - 1)}]`}></div>
        <div
          className="pc-ProductCard-ecomDataLayer"
          data-items={JSON.stringify(getEcomDataLayer(hit))}></div>
        {internalCatalog && (
          <div className="pc-ProductCard-internalSearch is-Internal">
            <div className="pc-ProductCard-quickDetails">
              {`Ink Limit: ${hit.color_limit}`}
            </div>
            <div className="pc-ProductCard-quickDetails">
              {`Decoration Type: ${capitalize(hit.decoration_method)}`}
            </div>
            <div className="pc-ProductCard-quickDetails">
              {`Specialty: ${hit.specialty}`}
            </div>
            <div className="pc-ProductCard-actions sb-Form pc-Form">
              <span className="pc-ProductCard-compare sb-Form pc-Form">
                <label className="sb-Form-checkbox">
                  <input
                    type="checkbox"
                    checked={comparedStyle()}
                    onChange={() => applyToComparisonForm(hit)}
                  />
                  <span className="sb-Form-indicator"></span>
                  Compare
                </label>
              </span>
              <span className="pc-ProductCard-quickView sb-Form pc-Form">
                <label
                  className="sb-Form-checkbox"
                  data-product-name={hit.name}>
                  <input
                    type="checkbox"
                    checked={false}
                    onChange={() => props.quickViewHandler(hit.product_id)}
                  />
                  <span
                    className="sb-Form-indicator"
                    data-product-name={hit.name}></span>
                  Quick View
                </label>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  hit: PropTypes.object
};

export default ProductCard;
