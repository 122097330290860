import {ALGOLIA_PERMITTED_NAV_PARAMS} from './algolia_constants';

const navigateToNoResultsPage = (inputValue) => {
  const {origin, searchParams} = new URL(window.location);
  searchParams.set('no_results', 'true');
  searchParams.set('keyword', inputValue);
  window.location.href = `${origin}/products/results/?${searchParams.toString()}`;
};

export const passThruSearchParams = (path) => {
  const persistParams = ALGOLIA_PERMITTED_NAV_PARAMS;
  const searchParams = new URLSearchParams(window.location.search);

  persistParams.forEach((param) => {
    if (searchParams.has(param)) {
      const separator = path.includes('?') ? '&' : '?';
      path += `${separator}${param}=${searchParams.get(param)}`;
    }
  });

  return path;
};

export const handleAutocompleteSelection = (searchClient, indexName, item) => {
  searchClient
    .search([
      {
        indexName,
        params: {
          hitsPerPage: 10,
          query: item
        }
      }
    ])
    .then(({results}) => {
      // algolia returning the redirect url from rules
      if(results[0].renderingContent?.redirect?.url){
        window.location = results[0].renderingContent.redirect.url;
      } else if (results[0].hits.length > 0) {
        window.location = passThruSearchParams(
          `/products/results?utf8=✓&keyword=${encodeURIComponent(item)}&searchType=catalog`
        );
      } else {
        navigateToNoResultsPage(item);
      }
    });
};
