import React from 'react';
import {Box, LightningIcon, Typography} from '@customink/pigment-react';

const DeliveryRushChip = ({label, superRush}) => {
  return (
    <Box
      data-testid="delivery-rush-chip"
      sx={theme => ({
        display: 'flex',
        borderRadius: '999px',
        background: '#FEEAE3',
        borderColor: 'red',
        p: '0.125rem 0.3125rem',
        alignItems: 'center',
        width: 'fit-content',
        flexShrink: 0,
        color: theme.palette.red.main
      })}>
      {superRush && (
        <LightningIcon
          data-testid="delivery-rush-chip-lightning-icon"
          sx={{ fontSize: '14px', marginLeft: '-1px' }}
        />
      )}
      <Typography
        data-testid="delivery-rush-chip-label"
        fontSize="0.75rem"
        fontStyle="italic"
        fontWeight={700}
        lineHeight="125%">
        {label}
      </Typography>
    </Box>
  );
};

export default DeliveryRushChip;
