import algoliasearch from 'algoliasearch';
import {createInMemoryCache} from '@algolia/cache-in-memory';

export const initializeAlgoliaClient = () => {
  return algoliasearch(
    process.env.ALGOLIA_V2_APPLICATION_ID,
    process.env.ALGOLIA_V2_SEARCH_API_KEY,
    {
      responsesCache: createInMemoryCache(),

      // Caches Promises with the same request payload
      requestsCache: createInMemoryCache({serializable: false})
    }
  );
};
