import {ColorSwatch} from '@customink/pigment-react';
import React, {useEffect, useState} from 'react';
import useBreakpoint from '../../hooks/useBreakpoint';
import {rgbToHex} from '../../utils/color';

const parseRGB = (rgb) => {
  const clr = {b: 0, g: 0, r: 0};
  const parts = rgb.replace('rgb(', '').replace(')', '').split(',');
  clr.r = parseInt(parts[0]);
  clr.g = parseInt(parts[1]);
  clr.b = parseInt(parts[2]);
  return clr;
};

function PopularSwatches(props) {
  const {colorsIn, onClickEvt, onMouseEnterEvt, useMinQty} = props;

  const colors = JSON.parse(JSON.stringify(colorsIn || [])); // direct usage causes infinite re-rendering (in some cases)
  const [popColorCount, setPopColorCount] = useState(0);
  const [popColors, setPopColors] = useState([]);
  const [remainingColorsCount, setRemainingColorsCount] = useState(0);
  const breakpoint = useBreakpoint();

  const breakpointData = {
    lg: {limit: 10, padding: '2px 16px', textAlign: 'center'},
    md: {limit: 8, padding: '2px 20px', textAlign: 'center'},
    sm: {limit: 4, margin: '0px', padding: '8px 0 0', textAlign: 'left'},
    xl: {limit: 14, padding: '2px 18px', textAlign: 'center'}
  };

  const getNextColor = (filteredColors, avoidThese) => {
    for (let x = 0; x < filteredColors.length; x += 1) {
      if (!avoidThese.includes(filteredColors[x])) {
        return filteredColors[x];
      }
    }
    return null;
  };

  const findBest = (filteredColors, simpleNames, rgbKey, mathSequence) => {
    const picks = {
      best: null,
      secondBest: null
    };
    filteredColors.forEach((color) => {
      const simpleName = color.simple_names[0];
      if (simpleNames.includes(simpleName)) {
        if (!picks.best) {
          picks.best = color;
        } else {
          const colorRgbs = color.rgbValues;
          const currentRgbs = picks.best.rgbValues;
          if (
            colorRgbs[mathSequence[0]] -
              colorRgbs[mathSequence[1]] -
              colorRgbs[mathSequence[2]] >
            0
          ) {
            if (colorRgbs[rgbKey] > currentRgbs[rgbKey]) {
              picks.secondBest = picks.best;
              picks.best = color;
            }
          }
        }
      }
    });
    return picks;
  };

  const limitBasedOnBreakpoint = () => {
    if (breakpointData[breakpoint]) return breakpointData[breakpoint].limit;
    return 14;
  };

  const findBestCombination = (
    filteredColors,
    simpleNames,
    rgbKeys,
    threshold
  ) => {
    const picks = {
      best: null,
      secondBest: null
    };
    filteredColors.forEach((color) => {
      const simpleName = color.simple_names[0];
      if (simpleNames.includes(simpleName)) {
        if (!picks.best) {
          picks.best = color;
        } else {
          const colorRgbs = color.rgbValues;
          const currentRgbs = picks.best.rgbValues;
          if (colorRgbs[rgbKeys[1]] + colorRgbs[rgbKeys[2]] > threshold) {
            if (colorRgbs[rgbKeys[0]] < currentRgbs[rgbKeys[0]]) {
              picks.secondBest = picks.best;
              picks.best = color;
            }
          }
        }
      }
    });
    return picks;
  };

  const pickOfSimple = (filteredColors, simpleName) => {
    const picks = {
      best: null,
      secondBest: null
    };
    filteredColors.forEach((color) => {
      if (color.simple_names[0] === simpleName) {
        if (picks.best) picks.secondBest = picks.best;
        picks.best = color;
      }
    });
    return picks;
  };

  const findBestWhite = (filteredColors) => {
    const picks = {
      best: null,
      secondBest: null
    };
    filteredColors.forEach((color) => {
      const simpleName = color.simple_names[0];
      if (simpleName === 'white') {
        if (!picks.best) {
          picks.best = color;
        } else {
          const colorRgbs = color.rgbValues;
          const currentRgbs = picks.best.rgbValues;
          if (
            colorRgbs.r + colorRgbs.g + colorRgbs.b >
            currentRgbs.r + currentRgbs.g + currentRgbs.b
          ) {
            picks.secondBest = picks.best;
            picks.best = color;
          }
        }
      }
    });
    return picks;
  };

  const findBestColors = (filteredColors) => {
    let limit = limitBasedOnBreakpoint();
    if (filteredColors.length - limit < 3) limit += 2;
    filteredColors.forEach((color) => {
      color.rgbValues = parseRGB(color.rbgs[0]);
    });
    let pops = [];
    const reds = findBest(filteredColors, ['red'], 'r', ['r', 'g', 'b']);
    const greens = findBest(filteredColors, ['green'], 'g', ['g', 'r', 'b']);
    const blues = findBest(filteredColors, ['blue'], 'b', ['b', 'r', 'g']);
    const yellows = findBestCombination(
      filteredColors,
      ['yellow', 'orange'],
      ['b', 'r', 'g'],
      300
    );
    const purples = findBestCombination(
      filteredColors,
      ['purple'],
      ['g', 'r', 'b'],
      150
    );
    const whites = findBestWhite(filteredColors);
    const browns = pickOfSimple(filteredColors, 'brown');
    const heathers = pickOfSimple(filteredColors, 'heathered');
    pops.push(blues.best);
    pops.push(heathers.best);
    pops.push(reds.best);
    pops.push(whites.best);
    pops.push(greens.best);
    pops.push(purples.best);
    pops.push(yellows.best);
    pops.push(browns.best);
    pops.push(blues.secondBest);
    pops.push(heathers.secondBest);
    pops.push(reds.secondBest);
    pops.push(whites.secondBest);
    pops.push(greens.secondBest);
    pops.push(purples.secondBest);
    pops.push(yellows.secondBest);
    pops.push(browns.secondBest);
    pops = pops.filter((i) => {
      return i !== null;
    });
    // fill with left-over colors
    for (let x = pops.length; x < limit; x += 1) {
      const nextColor = getNextColor(filteredColors, pops);
      if (nextColor) pops.push(nextColor);
    }
    if (pops.length > limit) {
      pops = pops.slice(0, limit);
    }
    setRemainingColorsCount(filteredColors.length - pops.length);
    return pops;
  };

  useEffect(() => {
    let filteredColors = colors;
    if (useMinQty) {
      filteredColors = colors.filter((c) => {
        return c.min_qty === 1;
      });
    }
    const pops = findBestColors(filteredColors);
    setPopColors(pops);
    setPopColorCount(pops.length);
  }, [useMinQty]);

  const textAlignBasedOnBreakpoint = () => {
    if (breakpointData[breakpoint])
      return breakpointData[breakpoint].textAlign || 'center';
    return 'center';
  };

  const getRemainingColorsPhrase = () => {
    if (remainingColorsCount === 0) return '';
    const token = remainingColorsCount === 1 ? 'color' : 'colors';
    return (
      <span className="more-colors">
        +{remainingColorsCount} {token}
      </span>
    );
  };

  const getAlignment = () => {
    if (popColors.length <= 6) return textAlignBasedOnBreakpoint();
    return 'left';
  };

  return (
    <div
      className="pc-ProductCard-popSwatches"
      style={{
        textAlign: getAlignment()
      }}>
      {popColorCount > 0 && (
        <>
          {popColors.map((color) => (
            <ColorSwatch
              className="pc-ProductCard-popSwatches-swatch"
              color={rgbToHex(color.rgbValues)}
              trimRgb={color.trim_hex}
              key={`popsw-${color.id}`}
              title={color.name || 'o_O'}
              onMouseEnter={(event) => onMouseEnterEvt(event, color)}
              onClick={(event) => onClickEvt(event, color)}
            />
          ))}
          {getRemainingColorsPhrase()}
        </>
      )}
    </div>
  );
}

export default PopularSwatches;
